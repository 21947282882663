const toggle = new Event('toggle'); // eslint-disable-line

export const Accordion = (el) => {
  const ui = {
    el,
    control: el.querySelector('.accordion__heading')
  };

  ui.control.addEventListener('keydown', (e) => {
    if (e.keyCode === 13) {
      ui.control.dispatchEvent(toggle);
    }
  });
};

export const AccordionGroup = (el) => {
  const ui = {
    el,
    accordions: el.querySelectorAll('.accordion')
  };

  const state = {
    controlled: ui.el.dataset.controlled
  };

  const onControlToggle = (current) => {
    // are any drawers open?
    ui.accordions.forEach((accordion) => {
      if (accordion !== current) {
        const hidden = accordion.querySelector('.expandable__drawer').getAttribute('aria-hidden');

        if (hidden === 'false') {
          accordion.querySelector('.expandable__toggle').dispatchEvent(toggle);
        }
      }
    });
  };

  const onToggle = (accordion) => {
    const drawer = accordion.querySelector('.expandable__drawer');
    const hidden = drawer.getAttribute('aria-hidden');

    if (hidden === 'false') {
      const links = drawer.querySelectorAll('.link');

      links.forEach((link) => {
        link.setAttribute('tabIndex', 1);
      });
    } else {
      const links = drawer.querySelectorAll('.link');

      links.forEach((link) => {
        link.setAttribute('tabIndex', -1);
      });
    }
  };

  const addEvents = () => {
    ui.accordions.forEach((accordion) => {
      accordion.addEventListener('click', () => onToggle(accordion));

      accordion.addEventListener('keydown', (e) => { if (e.keyCode === 13) { onToggle(accordion); } });
    });

    if (state.controlled === 'true') {
      ui.accordions.forEach((current) => {
        current.addEventListener('click', () => {
          onControlToggle(current);
        });

        current.addEventListener('keydown', (e) => {
          if (e.keyCode === 13) {
            onControlToggle(current);
          }
        });
      });
    }
  };

  const init = () => {
    addEvents();

    ui.accordions.forEach((accordion) => {
      const drawer = accordion.querySelector('.expandable__drawer');
      const hidden = drawer.getAttribute('aria-hidden');

      if (hidden === 'false') {
        const links = drawer.querySelectorAll('.link');

        links.forEach((link) => {
          link.setAttribute('tabIndex', 1);
        });
      } else {
        const links = drawer.querySelectorAll('.link');

        links.forEach((link) => {
          link.setAttribute('tabIndex', -1);
        });
      }
    });
  };

  init();
};

export default Accordion;
